/* Automatically generated file, do not update manually */
export default {
  VenusLens: {
    56: '0xe4C455cBf870A86399043B8A36A669FfA1583e95',
    97: '0x068b437aCD9F4668f77Aa24e2B715a41D4a9a599',
  },
  PoolLens: {
    1: '0x50695E540E87ceB4E39239C27B9ab160C5aaDd9D',
    56: '0xcD170B0638332242F7307a1Bc75E872738CEF7Cc',
    97: '0xdba6A250027049c4033970e224E61EDCD84eA630',
    204: '0x94B41D400F8baF1FBDc30BEA525ee99Ed3D60F51',
    300: '0x214Ed51c798EE53f0be79d810E9Fc91662FDE243',
    324: '0x69FC4232959131B4992597B739cEC97Ee898aA68',
    5611: '0x4b37f99703b2302127ba7E2785d32e3dF1F94452',
    42161: '0x53F34FF95367B2A4542461a6A63fD321F8da22AD',
    421614: '0xd08422A9A7b19404cC01FB970a247d19CE4EA7fE',
    11155111: '0xE4740d6C25D6CF4aE9DDbfE984f1C820A653318C',
  },
  PoolRegistry: {
    1: '0x61CAff113CCaf05FFc6540302c37adcf077C5179',
    56: '0x9F7b01A536aFA00EF10310A162877fd792cD0666',
    97: '0xC85491616Fa949E048F3aAc39fbf5b0703800667',
    204: '0x345a030Ad22e2317ac52811AC41C1A63cfa13aEe',
    300: '0x1401404e6279BB8C06E5E3999eCA3e2008B46A76',
    324: '0xFD96B926298034aed9bBe0Cca4b651E41eB87Bc4',
    5611: '0x560eA4e1cC42591E9f5F5D83Ad2fd65F30128951',
    42161: '0x382238f07Bc4Fe4aA99e561adE8A4164b5f815DA',
    421614: '0xf93Df3135e0D555185c0BC888073374cA551C5fE',
    11155111: '0x758f5715d817e02857Ba40889251201A5aE3E186',
  },
  LegacyPoolComptroller: {
    56: '0xfD36E2c2a6789Db23113685031d7F16329158384',
    97: '0x94d1820b2D1c7c7452A163983Dc888CEC546b77D',
  },
  VaiController: {
    56: '0x004065D34C6b18cE4370ced1CeBDE94865DbFAFE',
    97: '0xf70C3C6b749BbAb89C081737334E74C9aFD4BE16',
  },
  VaiVault: {
    56: '0x0667Eed0a0aAb930af74a3dfeDD263A73994f216',
    97: '0x7Db4f5cC3bBA3e12FF1F528D2e3417afb0a57118',
  },
  XvsTokenMultichain: {
    1: '0xd3CC9d8f3689B83c91b7B59cAB4946B063EB894A',
    204: '0x3E2e61F1c075881F3fB8dd568043d8c221fd5c61',
    300: '0x3AeCac43A2ebe5D8184e650403bf9F656F9D1cfA',
    324: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
    5611: '0xc2931B1fEa69b6D6dA65a50363A8D75d285e4da9',
    42161: '0xc1Eb7689147C81aC840d4FF0D298489fc7986d52',
    421614: '0x877Dc896e7b13096D3827872e396927BbE704407',
    11155111: '0x66ebd019E86e0af5f228a0439EBB33f045CBe63E',
  },
  XvsVault: {
    1: '0xA0882C2D5DF29233A092d2887A258C2b90e9b994',
    56: '0x051100480289e704d20e9DB4804837068f3f9204',
    97: '0x9aB56bAD2D7631B2A857ccf36d998232A8b82280',
    204: '0x7dc969122450749A8B0777c0e324522d67737988',
    300: '0x825f9EE3b2b1C159a5444A111A70607f3918564e',
    324: '0xbbB3C88192a5B0DB759229BeF49DcD1f168F326F',
    5611: '0xB14A0e72C5C202139F78963C9e89252c1ad16f01',
    42161: '0x8b79692AAB2822Be30a6382Eb04763A74752d5B4',
    421614: '0x407507DC2809D3aa31D54EcA3BEde5C5c4C8A17F',
    11155111: '0x1129f882eAa912aE6D4f6D445b2E2b1eCbA99fd5',
  },
  XvsStore: {
    1: '0x1Db646E1Ab05571AF99e47e8F909801e5C99d37B',
    56: '0x1e25CF968f12850003Db17E0Dba32108509C4359',
    97: '0x7B4dba3b320E7D421A213e1a73bB9858bb6839F8',
    204: '0xc3279442a5aCaCF0A2EcB015d1cDDBb3E0f3F775',
    300: '0xf0DaEFE5f5df4170426F88757EcdF45430332d88',
    324: '0x84266F552756cBed893b1FFA85248cD99501e3ce',
    5611: '0x06473fB3f7bF11e2E8EfEcC95aC55ABEFCb2e0A0',
    42161: '0x507D9923c954AAD8eC530ed8Dedb75bFc893Ec5e',
    421614: '0x4e909DA6693215dC630104715c035B159dDb67Dd',
    11155111: '0x03B868C7858F50900fecE4eBc851199e957b5d3D',
  },
  GovernorBravoDelegate: {
    56: '0x2d56dC077072B53571b8252008C60e945108c75a',
    97: '0x5573422A1a59385C247ec3a66B93B7C08eC2f8f2',
  },
  XvsVesting: {
    56: '0xb28Dec7C7Ac80f4D0B6a1B711c39e444cDE8B2cE',
    97: '0xcfa3a6bc934EcA22fa39e854c823cD3dE79D9BC8',
  },
  VrtConverter: {
    56: '0x92572fB60f4874d37917C53599cAe5b085B9Facd',
    97: '0xAb81e79F9607eC4C7b69Ab4f7cf6c0AF607aA131',
  },
  Maximillion: {
    56: '0x5efA1e46F4Fd738FF721F5AebC895b970F13E8A1',
    97: '0xF3a34e06015e019D6154a0f1089f695B27122f50',
  },
  XsequenceMulticall: {
    1: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    56: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    97: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    204: '0x3E5f527Adf40B65Fcbb4918e6507ecB89AF7Cdf5',
    300: '0x8C635a68D0DB098f90051D1D02ddB35B5B4EA592',
    324: '0xFEa0f491061cdb017041D6da43c98b6383097557',
    5611: '0x34D4c29902769a0168b9965CbC4147771206328D',
    42161: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    421614: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
    11155111: '0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E',
  },
  Multicall3: {
    1: '0xca11bde05977b3631167028862be2a173976ca11',
    56: '0xca11bde05977b3631167028862be2a173976ca11',
    97: '0xca11bde05977b3631167028862be2a173976ca11',
    204: '0xca11bde05977b3631167028862be2a173976ca11',
    300: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    5611: '0xca11bde05977b3631167028862be2a173976ca11',
    42161: '0xca11bde05977b3631167028862be2a173976ca11',
    421614: '0xca11bde05977b3631167028862be2a173976ca11',
    11155111: '0xca11bde05977b3631167028862be2a173976ca11',
  },
  ResilientOracle: {
    1: '0xd2ce3fb018805ef92b8C5976cb31F84b4E295F94',
    56: '0x6592b5DE802159F3E74B2486b091D11a8256ab8A',
    97: '0x3cD69251D04A28d887Ac14cbe2E14c52F3D57823',
    204: '0x8f3618c4F0183e14A218782c116fb2438571dAC9',
    300: '0x748853B3bE26c46b4562Fd314dfb82708F395bDf',
    324: '0xDe564a4C887d5ad315a19a96DC81991c98b12182',
    5611: '0xEF4e53a9A4565ef243A2f0ee9a7fc2410E1aA623',
    42161: '0xd55A98150e0F9f5e3F6280FC25617A5C93d96007',
    421614: '0x6708bAd042916B47311c8078b29d7f432342102F',
    11155111: '0x8000eca36201dddf5805Aa4BeFD73d1EB4D23264',
  },
  Prime: {
    1: '0x14C4525f47A7f7C984474979c57a2Dccb8EACB39',
    56: '0xBbCD063efE506c3D42a0Fa2dB5C08430288C71FC',
    97: '0xe840F8EC2Dc50E7D22e5e2991975b9F6e34b62Ad',
    300: '0x72b85930F7f8D00ACe5EAD10a315C17b8954FBcF',
    324: '0xdFe62Dcba3Ce0A827439390d7d45Af8baE599978',
    42161: '0xFE69720424C954A2da05648a0FAC84f9bf11Ef49',
    421614: '0xAdB04AC4942683bc41E27d18234C8DC884786E89',
    11155111: '0x2Ec432F123FEbb114e6fbf9f4F14baF0B1F14AbC',
  },
  VTreasury: {
    56: '0xF322942f644A996A617BD29c16bd7d231d9F35E9',
    97: '0x8b293600c50d6fbdc6ed4251cc75ece29880276f',
  },
  VTreasuryV8: {
    1: '0xFD9B071168bC27DBE16406eC3Aba050Ce8Eb22FA',
    204: '0xDDc9017F3073aa53a4A8535163b0bf7311F72C52',
    300: '0x943eBE4460a12F551D60A68f510Ea10CD8d564BA',
    324: '0xB2e9174e23382f7744CebF7e0Be54cA001D95599',
    5611: '0x3370915301E8a6A6baAe6f461af703e2498409F3',
    42161: '0x8a662ceAC418daeF956Bc0e6B2dd417c80CDA631',
    421614: '0x4e7ab1fD841E1387Df4c91813Ae03819C33D5bdB',
    11155111: '0x4116CA92960dF77756aAAc3aFd91361dB657fbF8',
  },
  XVSProxyOFTDest: {
    1: '0x888E317606b4c590BBAD88653863e8B345702633',
    204: '0x100D331C1B5Dcd41eACB1eCeD0e83DCEbf3498B2',
    300: '0x760461ccB2508CAAa2ECe0c28af3a4707b853043',
    324: '0x16a62B534e09A7534CD5847CFE5Bf6a4b0c1B116',
    5611: '0xA03205bC635A772E533E7BE36b5701E331a70ea3',
    42161: '0x20cEa49B5F7a6DBD78cAE772CA5973eF360AA1e6',
    421614: '0xFdC5cEC63FD167DA46cF006585b30D03B104eFD4',
    11155111: '0xc340b7d3406502F43dC11a988E4EC5bbE536E642',
  },
  XVSProxyOFTSrc: {
    56: '0xf8F46791E3dB29a029Ec6c9d946226f3c613e854',
    97: '0x0E132cd94fd70298b747d2b4D977db8d086e5fD0',
  },
  SwapRouter: {
    1: {},
    56: {
      '0xfd36e2c2a6789db23113685031d7f16329158384': '0x8938E6dA30b59c1E27d5f70a94688A89F7c815a4',
      '0x94c1495cd4c557f1560cbd68eab0d197e6291571': '0xBBd8E2b5d69fcE9Aaa599c50F0f0960AA58B32aA',
      '0x23b4404e4e5ec5ff5a6ffb70b7d14e3fabf237b0': '0xacD270Ed7DFd4466Bd931d84fe5B904080E28Bfc',
      '0x1b43ea8622e76627b81665b1ecebb4867566b963': '0x9B15462a79D0948BdDF679E0E5a9841C44aAFB7A',
      '0x3344417c9360b963ca93a4e8305361aede340ab9': '0x47bEe99BD8Cf5D8d7e815e2D2a3E2985CBCcC04b',
      '0xd933909a4a2b7a4638903028f44d1d38ce27c352': '0x5f0ce69Aa564468492e860e8083BB001e4eb8d56',
      '0x33b6fa34cd23e5aeed1b112d5988b026b8a5567d': '0x9Db0CBD9A73339949f98C5E6a51e036d0dEaFf21',
    },
    97: {
      '0x94d1820b2d1c7c7452a163983dc888cec546b77d': '0x83edf1deE1B730b7e8e13C00ba76027D63a51ac0',
      '0x10b57706ad2345e590c2ea4dc02faef0d9f5b08b': '0x8Ff3c0a74b4CBD4dFA3A35Cca756490bE351F936',
      '0x11537d023f489e4ef0c7157cc729c7b69cbe0c97': '0x1D8cA5AFB88F07489786A3d2E0FF50F3F9314d97',
      '0x1f4f0989c51f12dacacd4025018176711f3bf289': '0x5D254Bc7c7f2670395B9E0716C21249083D41a4f',
      '0x23a73971a6b9f6580c048b9cb188869b2a2aa2ad': '0x89Bc8dFe0Af08b60ec285071d133FCdfa9B3C08e',
      '0x596b11acaacf03217287939f88d63b51d3771704': '0xb16792E90d6478DaBbd0144e13f41CeA21ACE116',
      '0x92e8e3c202093a495e98c10f9fcaa5abe288f74a': '0x18995825f033F33fa30CF59c117aD21ff6BdB48c',
    },
    204: {},
    300: {},
    324: {},
    5611: {},
    42161: {},
    421614: {},
    11155111: {},
  },
  NativeTokenGateway: {
    1: {
      '0x687a01ecf6d3907658f7a7c714749fac32336d1b': '0x044dd75b9E043ACFD2d6EB56b6BB814df2a9c809',
      '0xf522cd0360ef8c2ff48b648d53ea1717ec0f3ac3': '0xBC1471308eb2287eBE137420Eb1664A964895D21',
    },
    56: {
      '0xd933909a4a2b7a4638903028f44d1d38ce27c352': '0x24896601A4bf1b6a27E51Cb3eff750Bd9FE00d08',
    },
    97: {
      '0x596b11acaacf03217287939f88d63b51d3771704': '0xCf4C75398DaD73f16c762026144a1496f6869CD1',
    },
    204: {
      '0xd6e3e2a1d8d95cae355d15b3b9f8e5c2511874dd': '0x7bAf6019C90B93aD30f8aD6a2EcCD2B11427b29f',
    },
    300: {
      '0xc527de08e43aefd759f7c0e6ae85433923064669': '0xC2bc5881f2c1ee08a1f0fee65Fbf2BB4C4DD81e9',
    },
    324: {
      '0xdde4d098d9995b659724ae6d5e3fb9681ac941b1': '0xeEDE4e1BDaC489BD851970bE3952B729C4238A68',
    },
    5611: {
      '0x2fcabb31e57f010d623d8d68e1e18aed11d5a388': '0x78FB73687209019CC1799B99Af30b6FB0A5b8e14',
    },
    42161: {
      '0x317c1a5739f39046e20b08ac9beea3f10fd43326': '0xc8e51418cadc001157506b306C6d0b878f1ff755',
      '0x52bab1af7ff770551bd05b9fc2329a0bf5e23f16': '0xD1e89806BAB8Cd7680DFc7425D1fA6d7D5F0C3FE',
    },
    421614: {
      '0x006d44b6f5927b3ed83bd0c1c36fb1a3bacac208': '0x196b19C2037863409C65CbF63592ae2a3CD2Dc2C',
      '0x3d04f926b2a165bba17fbfccccb61513634fa5e4': '0x63cEE24b12648E36d708163587aC17a777096a47',
    },
    11155111: {
      '0x7aa39ab4bca897f403425c9c6fdbd0f882be0d70': '0xb8fD67f215117FADeF06447Af31590309750529D',
      '0xd79ceb8ef8188e44b7eb899094e8a3a4d7a1e236': '0x1FD30e761C3296fE36D9067b1e398FD97B4C0407',
    },
  },
};
